import checkoutActions from '../actions/checkoutData'

const defaultState = {
  data: {},
  loading: false,
}

const checkoutReducer = (state = defaultState, action) => {
  switch (action.type) {
    case checkoutActions.SET_CHECKOUT_DATA: {
      return {
        ...state,
        data: {
          ...state.data,
          checkout_data: action.payload,
        },
      }
    }
    
    default:
      return state
  }
}

export default checkoutReducer
