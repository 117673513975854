import request from './createRequest/rendaextra'

const show = async ({ identifier, deviceToken }) => {
  const header = {
    headers: {
      'x-device-token': deviceToken,
    },
  }

  try {
    const { data } = await request.get(
      `/referrers/${identifier}/metadata/public`,
      header
    )

    return {
      referrerName: data.name,
      referrerCoupon: data.coupon,
      referrerId: data.referrer_id,
      referrerCustomOffer: data.custom_offer,
      referrerSubcoupon: data.subcoupon,
    }
  } catch (error) {
    if (error.statusCode === 409) {
      return Promise.reject(error)
    }

    return Promise.resolve({ referrerCoupon: identifier })
  }
}

export default {
  show,
}
