import actionCreator from './actionCreator'

const SET_UTM_TAGS = 'SET_UTM_TAGS'

const setUtmTags = actionCreator(SET_UTM_TAGS)

export default {
  SET_UTM_TAGS,
  setUtmTags,
}
