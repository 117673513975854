export const GA_SETTINGS = {
  UACode: 'UA-104644236-1',
}

export const CATEGORIES = {
  ADD_TO_CART: 'Adicionou ao carrinho',
  ADD_MORE_PRODUCTS: 'Adicionou mais produtos',
  ACTIVE_COUPON: 'Ativou cupom de desconto',
  REMOVE_FROM_CART: 'Removeu do carrinho',
  FINISH_ORDER: 'Fechou pedido',
  FUNNEL_EMAIL: 'Funil - Email',
  REGISTER_EMAIL: 'Cadastrou e-mail',
  ORDER_WHATSAPP: 'Pediu por whatsapp',
  FUNNEL_PERSONAL_DATA: 'Funil - Dados Pessoais',
  REGISTER_PERSONAL_DATA: 'Cadastrou dados pessoais',
  FUNNEL_BUSINESS_DATA: 'Funil - Dados do Negócio',
  REGISTER_BUSINESS_DATA: 'Cadastrou dados do negócio',
  REGISTER_INSURANCE_DATA: 'Cadastrou dados do seguro ton',
  FUNNEL_DELIVERY_LOCATION: 'Funil - Local de Entrega',
  REGISTER_DELIVERY_LOCATION: 'Cadastrou local de entrega',
  FUNNEL_PAYMENT: 'Funil - Pagamento',
  FINISH_PAYMENT: 'Concluiu pagamento',
}

export const ACTIONS = {
  VALID_COUPON: 'Cupom válido',
  INVALID_COUPON: 'Cupom inválido',
  CREDIT_CARD: 'Cartão de crédito',
  PAYMENT_SLIP: 'Boleto',
  PAYMENT_PIX: 'Pix',
}
