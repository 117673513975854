import { parseLeadFields } from 'src/utils/lead'
import { Lead } from 'src/types/lead'

import request from './createRequest/lead'

type LeadResponse = {
  id: string
}

const createLead = async (): Promise<{ id: string }> => {
  const res = await request.post('/v1/')

  if (!res) {
    throw new Error('Error at lead request')
  }

  return res.data as LeadResponse
}

const updateLead = async (id: string, fields: Lead) => {
  const body = parseLeadFields(fields)
  await request.put(`/v1/${id}`, body)
}

export default {
  createLead,
  updateLead,
}
