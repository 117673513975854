import { Lead } from '../types/lead'

const replacements: { [k: string]: string } = {
  companyName: 'company_name',
  documentType: 'document_type',
  motherName: 'mother_name',
  newUser: 'new_user',
  userId: 'user_id',
  cartId: 'cart_id',
  orderId: 'order_id',
}

export const parseLeadFields = (fields: Lead) => {
  const { utm, address, ...restFields } = fields
  const parsedUtms = {}
  const parsedFields = {}

  if (utm) {
    Object.entries(utm).forEach(([key, value]) => {
      Object.assign(parsedUtms, { [`utm_${key}`]: value })
    })
  }

  if (fields.birthdate) {
    const [day, month, year] = fields.birthdate.split('/')
    restFields.birthdate = `${year}-${month}-${day}`
  }

  if (fields.phone) {
    restFields.phone = fields.phone.replace(/\D/g, '')
  }

  Object.entries(restFields).forEach(([key, value]) => {
    Object.assign(parsedFields, { [replacements[key] || key]: value as string })
  })

  return {
    address,
    ...parsedFields,
    ...parsedUtms,
  }
}
