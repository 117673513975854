import { combineReducers } from 'redux'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import cart, { defaultState as cartDefaultState } from './cart'
import products from './products'
import user from './user'
import utm from './utm'
import trace from './trace'
import stepForm from './stepForm'
import appActions from '../actions/app'
import checkoutData from './checkoutData'

const tracePersistConfig = { key: 'trace', storage }

const appReducers = combineReducers({
  cart,
  products,
  user,
  utm,
  trace: persistReducer(tracePersistConfig, trace),
  stepForm,
  checkoutData,
})

const rootReducer = (state, action) => {
  if (action.type === appActions.CLEAN_APP_DATA) {
    // eslint-disable-next-line no-param-reassign
    state = {
      cart: {
        ...cartDefaultState,
        catalog: state.cart.catalog,
      },
      trace: state.trace,
    }
  }

  return appReducers(state, action)
}
export default rootReducer
