import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useCallback,
} from 'react'

import leadService from 'src/services/lead'
import { Lead } from 'src/types/lead'

type LeadContextType = {
  id: string | null
  updateLead?: (leadProps: Lead) => Promise<void>
}

const leadContextDefaultValues: LeadContextType = {
  id: null,
}

const sessionStorageKey = 'ton-lead'

const LeadContext = createContext<LeadContextType>(leadContextDefaultValues)

export function useLead() {
  return useContext(LeadContext)
}

export const LeadProvider: React.FC = ({ children }) => {
  const [lead, setLead] = useState<string | null>(null)

  const createNewLead = useCallback(async () => {
    try {
      const { id } = await leadService.createLead()
      window.sessionStorage.setItem(sessionStorageKey, id)
      setLead(id)
    } catch (err) {
      // error on createNewLead
    }
  }, [])

  const updateLead = useCallback(
    async (leadProps: Lead) => {
      if (lead) {
        await leadService.updateLead(lead, leadProps)
      }
    },
    [lead]
  )

  useEffect(() => {
    const sessionLead = window.sessionStorage.getItem(sessionStorageKey)

    if (sessionLead) {
      setLead(sessionLead)
    } else {
      void createNewLead()
    }
  }, [createNewLead])

  return (
    <LeadContext.Provider
      value={{
        id: lead,
        updateLead,
      }}
    >
      {children}
    </LeadContext.Provider>
  )
}
