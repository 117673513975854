import React, { useEffect } from 'react'
import { Route, Redirect, Switch, withRouter, useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import { connect, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import { MuiThemeProvider } from '@material-ui/core'

import productActions from 'src/redux/actions/products'
import { createBrowserId as createBrowserIdAction } from 'src/redux/actions/trace'
import asyncComponent from 'src/components/AsyncComponent'
import style from 'src/helpers/style'
import { useUserTag } from 'src/hooks/useUserTag'
import { CATALOG_MAP, PRODUCT_MAP } from 'src/constants/catalogs'

const Cart = asyncComponent(() => import('./Cart'))
const EmailVerification = asyncComponent(() =>
  import('./AccountVerication/EmailVerification')
)
const NewAccount = asyncComponent(() =>
  import('./AccountVerication/NewAccount')
)
const PersonalInfoForm = asyncComponent(() =>
  import('./StepForm/PersonalInfoForm')
)
const PersonalAddressForm = asyncComponent(() =>
  import('./StepForm/PersonalAddressForm')
)
const DeliveryDataForm = asyncComponent(() =>
  import('./StepForm/DeliveryDataForm')
)
const BusinessInfoForm = asyncComponent(() =>
  import('./StepForm/BusinessInfoForm')
)
const BusinessAddressForm = asyncComponent(() =>
  import('./StepForm/BusinessAddressForm')
)
const BusinessCnpjInfoForm = asyncComponent(() =>
  import('./StepForm/BusinessCnpjInfoForm')
)
const InsuranceInfoForm = asyncComponent(() =>
  import('./StepForm/InsuranceInfoForm')
)
const InsuranceOffer = asyncComponent(() => import('./InsuranceOffer'))
const PaymentForm = asyncComponent(() => import('./StepForm/PaymentForm'))
const ConfirmationCheck = asyncComponent(() => import('./ConfirmationCheck'))
const SuccessCreditCard = asyncComponent(() => import('./SuccessCreditCard'))
const SuccessPix = asyncComponent(() => import('./SuccessPix'))
const SuccessBoleto = asyncComponent(() => import('./SuccessBoleto'))
const ForgotPassword = asyncComponent(() =>
  import('./AccountVerication/ForgotPassword')
)
const RedefinePassword = asyncComponent(() =>
  import('./AccountVerication/RedefinePassword')
)
const OrderPayment = asyncComponent(() => import('./OrderPayment'))
const OrderPaid = asyncComponent(() => import('./OrderPaid'))
const OrderExpired = asyncComponent(() => import('./OrderExpired'))

const App = ({ fetchProducts, createBrowserId }) => {
  const loadingProducts = useSelector((state) => state.products.loading)
  const catalog = useUserTag()
  const location = useLocation()

  useEffect(() => {
    if (catalog) {
      fetchProducts({ catalog, extraProduct: true })
      createBrowserId()
    }
  }, [catalog])

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(location.search)
    const productId = urlSearchParams.get('productId')

    const redirectedUser = CATALOG_MAP[urlSearchParams.get('userTag')]
    if (!redirectedUser) {
      return
    }

    urlSearchParams.set('userTag', redirectedUser)

    if (productId) {
      const redirectedProduct = PRODUCT_MAP[productId]

      if (redirectedProduct) {
        urlSearchParams.set('productId', redirectedProduct)
      } else {
        urlSearchParams.delete('productId')
      }
    }

    window.location.href = `/checkout/cart?${urlSearchParams.toString()}`
  }, [catalog, location.search])

  if (loadingProducts) {
    return <h1>...</h1>
  }
  return (
    <MuiThemeProvider theme={style.theme}>
      <Switch>
        <Route
          exact
          path="/"
          render={({ location: routeLocation }) =>
            routeLocation.hash ? (
              <Redirect to={routeLocation.hash.replace('#', '')} />
            ) : (
              <Redirect to={`/cart${routeLocation.search}`} />
            )
          }
        />
        <Route path="/cart" component={Cart} />
        <Route path="/email-verification" component={EmailVerification} />
        <Route path="/forgot-password" component={ForgotPassword} />
        <Route path="/new-account" component={NewAccount} />
        <Route path="/redefine-password/:token" component={RedefinePassword} />

        <Route path="/steps/insurance-offer" component={InsuranceOffer} />
        <Route path="/steps/insurance-info" component={InsuranceInfoForm} />
        <Route path="/steps/personal-info" component={PersonalInfoForm} />
        <Route
          path="/steps/personal-address-info"
          component={PersonalAddressForm}
        />
        <Route path="/steps/delivery-data" component={DeliveryDataForm} />
        <Route path="/steps/business-info" component={BusinessInfoForm} />
        <Route path="/steps/business-address" component={BusinessAddressForm} />
        <Route
          path="/steps/business-cnpj-info"
          component={BusinessCnpjInfoForm}
        />
        <Route path="/steps/payment" component={PaymentForm} />

        <Route path="/payment/:orderId" component={OrderPayment} />
        <Route path="/order-paid" component={OrderPaid} />
        <Route path="/order-expired" component={OrderExpired} />

        <Route path="/confirmation" component={ConfirmationCheck} />
        <Route path="/success-credit-card" component={SuccessCreditCard} />
        <Route path="/success-boleto" component={SuccessBoleto} />
        <Route path="/success-pix" component={SuccessPix} />

        <Redirect from="" to="/cart" />
      </Switch>
    </MuiThemeProvider>
  )
}

App.propTypes = {
  fetchProducts: PropTypes.func.isRequired,
  createBrowserId: PropTypes.func.isRequired,
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchProducts: productActions.fetchProducts,
      createBrowserId: createBrowserIdAction,
    },
    dispatch
  )

const EnhancedApp = connect(null, mapDispatchToProps)(App)
export default withRouter(EnhancedApp)
