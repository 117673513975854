import { combineEpics } from 'redux-observable'
import {
  filter,
  mergeMap,
  switchMap,
  map,
  withLatestFrom,
  debounceTime,
} from 'rxjs/operators'
import cartActions from '../../actions/cart'
import cartService from '../../../services/cart'
import referrerMetadataService from '../../../services/referrerMetadata'

const createCart$ = (action$) =>
  action$.pipe(
    filter((action) => action.type === cartActions.CREATE_CART),
    map((action) => action.payload),
    mergeMap((cartData) =>
      cartService
        .createCart(cartData)
        .then(cartActions.createCartSuccess)
        .catch(cartActions.createCartFailed)
    )
  )

const getCartById$ = (action$) =>
  action$.pipe(
    filter((action) => action.type === cartActions.GET_CART_BY_ID),
    map((action) => action.payload),
    mergeMap((cartId) =>
      cartService
        .getCartById(cartId)
        .then(cartActions.getCartByIdSuccess)
        .catch(cartActions.getCartByIdFailed)
    )
  )

const updatableActionTypes = [
  cartActions.CHANGE_PRODUCT_QUANTITY,
  cartActions.INCREMENT_PRODUCT_QUANTITY,
  cartActions.DECREMENT_PRODUCT_QUANTITY,
  cartActions.REMOVE_PRODUCT,
  cartActions.CHANGE_PRODUCT_CARRIER,
  cartActions.ADD_NEW_PRODUCT,
]

const updateCart$ = (action$, state$) =>
  action$.pipe(
    filter((action) => updatableActionTypes.includes(action.type)),
    withLatestFrom(state$),
    map(([, state]) => state.cart.data),
    debounceTime(200),
    switchMap((cartData) =>
      cartService
        .updateOrCreateCart(cartData)
        .then(cartActions.updateCartSuccess)
        .catch(cartActions.updateCartFailed)
    )
  )

const fetchReferrerMetadata$ = (action$) =>
  action$.pipe(
    filter((action) => action.type === cartActions.FETCH_REFERRER_METADATA),
    map((action) => action.payload),
    switchMap(({ identifier, deviceToken }) =>
      referrerMetadataService
        .show({ identifier, deviceToken })
        .then((response) =>
          cartActions.addCoupon({
            coupon: response.referrerCoupon,
            referrerId: response.referrerId,
            referrerName: response.referrerName,
            referrerCustom: response.referrerCustomOffer || undefined,
            subcoupon: response.referrerSubcoupon || undefined,
          })
        )
        .catch(cartActions.fetchReferrerMetadataError)
    )
  )

const couponActionTypes = [cartActions.ADD_COUPON, cartActions.REMOVE_COUPON]

const addCoupon$ = (action$, state$) =>
  action$.pipe(
    filter((action) => couponActionTypes.includes(action.type)),
    withLatestFrom(state$),
    map(([, state]) => state.cart.data),
    debounceTime(200),
    switchMap((cartData) =>
      cartService
        .updateOrCreateCart(cartData)
        .then(cartActions.updateCartSuccess)
        .catch(cartActions.addCouponFailed)
    )
  )

export default combineEpics(
  addCoupon$,
  fetchReferrerMetadata$,
  createCart$,
  updateCart$,
  getCartById$
)
