import { createTheme } from '@material-ui/core'

const theme = createTheme({
  palette: {
    primary: {
      main: '#14AA4B',
      contrastText: '#fff',
    },
    secondary: {
      main: '#7810D4',
      contrastText: '#fff',
    },
  },
  overrides: {
    MuiButton: {
      root: {
        textTransform: 'none',
      },
      sizeLarge: {
        height: 56,
      },
    },
    MuiIconButton: {
      root: {
        '&:hover': {
          backgroundColor: '#F7F8FA',
        },
      },
    },
  },
})

const greenTheme = createTheme({
  palette: {
    primary: {
      main: '#1EC677',
    },
  },
  overrides: {
    MuiButton: {
      raisedPrimary: {
        color: 'white',
      },
    },
  },
  typography: {
    button: {
      textTransform: 'none',
    },
  },
})

export default {
  theme,
  greenTheme,
}
