import actionCreator from './actionCreator'

const SET_USER_DOCUMENT_TYPE = 'SET_USER_DOCUMENT_TYPE'
const SET_BUSINESS_ADDRESS = 'SET_BUSINESS_ADDRESS'
const SET_DELIVERY_ADDRESS = 'SET_DELIVERY_ADDRESS'
const UPDATE_USER = 'UPDATE_USER'

const setUserDocumentType = actionCreator(SET_USER_DOCUMENT_TYPE)
const setBusinessAddress = actionCreator(SET_BUSINESS_ADDRESS)
const setDeliveryAddress = actionCreator(SET_DELIVERY_ADDRESS)
const updateUser = actionCreator(UPDATE_USER)

export default {
  SET_USER_DOCUMENT_TYPE,
  SET_BUSINESS_ADDRESS,
  SET_DELIVERY_ADDRESS,
  UPDATE_USER,
  setUserDocumentType,
  setDeliveryAddress,
  updateUser,
  setBusinessAddress,
}
