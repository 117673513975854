import actionCreator from './actionCreator'

const CLEAN_APP_DATA = 'CLEAN_APP_DATA'

const cleanAppData = actionCreator(CLEAN_APP_DATA)

export default {
  cleanAppData,
  CLEAN_APP_DATA,
}
