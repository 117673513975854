import moment from 'moment'
import { v4 as uuidv4 } from 'uuid'
import { types } from '../actions/trace'

const defaultState = {
  etn: uuidv4(),
  dbc: new Date(),
  ui: [],
  pd: [],
}

const traceReducer = (state = defaultState, action) => {
  switch (action.type) {
    case types.CREATE_BROWSER_ID: {
      const traceExpiration = moment().subtract(1, 'w')
      if (moment(state.dbc) < traceExpiration) {
        return {
          ...state,
          dbc: new Date(),
          ui: [],
          pd: [],
        }
      }

      return state
    }
    case types.CREATE_USER_SUCCESS: {
      const { userData } = action.payload

      return {
        ...state,
        ui: state.ui.concat(userData.id),
      }
    }
    case types.CREATE_CREDIT_CARD_TRX: {
      const { order } = action.payload

      return {
        ...state,
        pd: state.pd.concat({
          oi: order.id,
        }),
      }
    }
    default:
      return state
  }
}

export default traceReducer
