import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { CATALOG_MAP } from 'src/constants/catalogs'

const useUserTag = () => {
  const [userTagState, setUserTag] = useState('')
  const cartCatalog = useSelector((state) => state.cart.catalog)

  useEffect(() => {
    const urlParams = new URLSearchParams(
      window.location.href.substring(window.location.href.indexOf('?') + 1)
    )

    const userTagParam = urlParams.get('userTag')

    const userTag =
      CATALOG_MAP[userTagParam] || userTagParam || cartCatalog || 'tonsuper'

    if (userTag !== userTagState) {
      setUserTag(userTag)
    }
  }, [window.location.href])

  return userTagState
}

export { useUserTag }
