import { createStore, applyMiddleware, compose } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage/session'
import { composeWithDevTools } from 'redux-devtools-extension'
import { createEpicMiddleware } from 'redux-observable'
import reducers from './reducers'
import epics from './epics'

const { NODE_ENV } = process.env
const epicMiddleware = createEpicMiddleware()
const isDev = NODE_ENV === 'development'

const composeEnhancers = isDev ? composeWithDevTools : compose

/**
 * whitelist: which parts of the state will be persisted
 */
const persistConfig = {
  key: 'root',
  storage,
}

const persistedReducer = persistReducer(persistConfig, reducers)
const store = createStore(
  persistedReducer,
  composeEnhancers(applyMiddleware(epicMiddleware))
)

epicMiddleware.run(epics)

const persistor = persistStore(store)

export default store
export { persistor }
