import actionCreator from './actionCreator'

const FETCH_PRODUCTS = 'FETCH_PRODUCTS'
const FETCH_PRODUCTS_SUCCESS = 'FETCH_PRODUCTS_SUCCESS'
const FETCH_PRODUCTS_FAILED = 'FETCH_PRODUCTS_FAILED'

const types = {
  FETCH_PRODUCTS,
  FETCH_PRODUCTS_SUCCESS,
  FETCH_PRODUCTS_FAILED,
}

const fetchProducts = actionCreator(FETCH_PRODUCTS)
const fetchProductsSuccess = actionCreator(FETCH_PRODUCTS_SUCCESS)
const fetchProductsFailed = actionCreator(FETCH_PRODUCTS_FAILED)

export default {
  types,
  fetchProducts,
  fetchProductsSuccess,
  fetchProductsFailed,
}
