import createRequest from '.'

const {
    REACT_APP_X_REALM
} = process.env

const createRequestTon = (HOST) => createRequest(HOST, {
    headers: {
        'x-realm': REACT_APP_X_REALM,
    },
})

export default createRequestTon