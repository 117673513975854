/**
 * Comment from stone developer:
 * This code is a copy from https://github.com/amplitude/ampli-examples/tree/main/browser/typescript/v2/react-app/src
 * The objective of this copy is get a typed implementation of
 * @amplitude/analytics-browser library because at moment there are no a typed
 * official implementation library or types to install
 *
 * just functions ensureGetUserId, getGASessionId and getDeviceType was made by me
 * function track fom Ampli class was changed by me to own default properties
 */

/* tslint:disable */
/* eslint-disable */
/**
 * Ampli - A strong typed wrapper for your Analytics
 *
 * This file is generated by Amplitude.
 * To update run 'ampli pull browser-ts-ampli-v2'
 *
 * Required dependencies: @amplitude/analytics-browser@^1.3.0
 * Tracking Plan Version: 1
 * Build: 1.0.0
 * Runtime: browser:typescript-ampli-v2
 *
 * [View Tracking Plan](https://data.amplitude.com/test-codegen/Test%20Codegen/events/main/latest)
 *
 * [Full Setup Instructions](https://data.amplitude.com/test-codegen/Test%20Codegen/implementation/browser-ts-ampli-v2)
 */

import * as amplitude from '@amplitude/analytics-browser'

export type Environment = 'prod' | 'dev'

export const ApiKey: Record<Environment, string> = {
  prod: '',
  dev: '',
}

/**
 * Default Amplitude configuration options. Contains tracking plan information.
 */
export const DefaultConfiguration: BrowserOptions = {
  plan: {
    version: '1',
    branch: 'main',
    source: 'browser-ts-ampli-v2',
    versionId: 'a61c3908-ca4d-4c8d-8f81-54ad3ba17b9c',
  },
  ...{
    ingestionMetadata: {
      sourceName: 'browser-typescript-ampli',
      sourceVersion: '2.0.0',
    },
  },
  defaultTracking: {
    formInteractions: false,
  },
}

export interface LoadOptionsBase {
  disabled?: boolean
}

export type LoadOptionsWithEnvironment = LoadOptionsBase & {
  environment: Environment
  client?: { configuration?: BrowserOptions }
}
export type LoadOptionsWithApiKey = LoadOptionsBase & {
  client: { apiKey: string; configuration?: BrowserOptions }
}
export type LoadOptionsWithClientInstance = LoadOptionsBase & {
  client: { instance: BrowserClient }
}

export type LoadOptions =
  | LoadOptionsWithEnvironment
  | LoadOptionsWithApiKey
  | LoadOptionsWithClientInstance

export interface IdentifyProperties {
  /**
   * Description for identify optionalArray
   *
   * | Rule | Value |
   * |---|---|
   * | Unique Items | false |
   * | Item Type | string |
   */
  optionalArray?: string[]
  /**
   * Description for identify requiredNumber
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  requiredNumber: number
}

export interface EventObjectTypesProperties {
  /**
   * Property Object Type
   */
  requiredObject: any
  /**
   * Property Object Array Type
   */
  requiredObjectArray: any[]
}

export interface EventWithAllPropertiesProperties {
  /**
   * Event 2 Property - Optional String    *     * Examples:    * Some string, or another
   */
  optionalString?: string
  /**
   * Event 2 Property - Array
   *
   * | Rule | Value |
   * |---|---|
   * | Min Items | 0 |
   * | Item Type | string |
   *
   * @minItems 0
   */
  requiredArray: string[]
  /**
   * Event 2 Property - Boolean
   */
  requiredBoolean: boolean
  /**
   * Event 2 Property - Enum
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Enum1, Enum2 |
   */
  requiredEnum: 'Enum1' | 'Enum2'
  /**
   * Event 2 Property - Integer    *     * Examples:    * 5, 4, 3
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  requiredInteger: number
  /**
   * Event 2 Property - Number
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  requiredNumber: number
  /**
   * Event 2 Property - String
   */
  requiredString: string
}

export interface EventWithArrayTypesProperties {
  /**
   * description for required boolean array
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | boolean |
   */
  requiredBooleanArray: boolean[]
  /**
   * Description for enum array property
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  requiredEnumArray: ('enum1' | 'enum2')[]
  /**
   * Description for required number array
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | number |
   */
  requiredNumberArray: number[]
  /**
   * Description for required object array
   */
  requiredObjectArray: any[]
  /**
   * description for required string array
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  requiredStringArray: string[]
}

export interface EventWithEnumTypesProperties {
  /**
   * Description for required enum
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | optional enum 1, optional enum 2 |
   */
  'optional enum'?: 'optional enum 1' | 'optional enum 2'
  /**
   * Description for optional enum
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | required enum 1, required enum 2 |
   */
  'required enum': 'required enum 1' | 'required enum 2'
}

export interface EventWithOptionalArrayTypesProperties {
  /**
   * Description for optional boolean array
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | boolean |
   */
  optionalBooleanArray?: boolean[]
  /**
   * Description for optional enum array
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  optionalEnumArray?: ('enum1' | 'enum2')[]
  /**
   * Description for optional object array
   */
  optionalJSONArray?: any[]
  /**
   * Description for optional number array
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | number |
   */
  optionalNumberArray?: number[]
  /**
   * Description for optional string array
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  optionalStringArray?: string[]
}

export interface EventWithOptionalPropertiesProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | number |
   */
  optionalArrayNumber?: number[]
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  optionalArrayString?: string[]
  optionalBoolean?: boolean
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  optionalNumber?: number
  /**
   * Optional String property description
   */
  optionalString?: string
}

export interface EventWithTemplatePropertiesProperties {
  /**
   * optional_event_property description
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  optional_event_property?: number
  /**
   * optional_template_property description
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  optional_template_property?: number
  /**
   * required_event_property description
   */
  required_event_property: string
  /**
   * required_template_property description
   */
  required_template_property: string
}

export interface EventWithDifferentCasingTypesProperties {
  /**
   * Description for enum with space
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | enum with space |
   */
  'enum with space': 'enum with space'
  /**
   * description_for_enum_snake_case
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | enum_snake_case |
   */
  enum_snake_case: 'enum_snake_case'
  /**
   * descriptionForEnumCamelCase
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | enumCamelCase |
   */
  enumCamelCase: 'enumCamelCase'
  /**
   * DescirptionForEnumPascalCase
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | EnumPascalCase |
   */
  EnumPascalCase: 'EnumPascalCase'
  /**
   * Description for case with space
   */
  'property with space': string
  /**
   * Description_for_snake_case
   */
  property_with_snake_case: string
  /**
   * descriptionForCamelCase
   */
  propertyWithCamelCase: string
  /**
   * DescriptionForPascalCase
   */
  PropertyWithPascalCase: string
}

export interface EventMaxIntForTestProperties {
  /**
   * property to test schema validation
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Max Value | 10 |
   */
  intMax10: number
}

export interface EventTemplateProperties {
  /**
   * optional_template_property description
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  optional_template_property?: number
  /**
   * required_template_property description
   */
  required_template_property: string
}

export interface SourceTemplateProperties {
  /**
   * description for context optionalEnum
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Value 1, Value 2 |
   */
  optionalEnum?: 'Value 1' | 'Value 2'
  /**
   * description for context requiredString
   */
  requiredString: string
}

export class Identify implements BaseEvent {
  event_type = amplitude.Types.SpecialEventType.IDENTIFY

  constructor(public event_properties: IdentifyProperties) {
    this.event_properties = event_properties
  }
}

export class EventNoProperties implements BaseEvent {
  event_type = 'Event No Properties'
}

export class EventObjectTypes implements BaseEvent {
  event_type = 'Event Object Types'

  constructor(public event_properties: EventObjectTypesProperties) {
    this.event_properties = event_properties
  }
}

export class EventWithAllProperties implements BaseEvent {
  event_type = 'Event With All Properties'
  event_properties: EventWithAllPropertiesProperties & {
    requiredConst: 'some-const-value'
  }

  constructor(event_properties: EventWithAllPropertiesProperties) {
    this.event_properties = {
      ...event_properties,
      requiredConst: 'some-const-value',
    }
  }
}

export class EventWithArrayTypes implements BaseEvent {
  event_type = 'Event With Array Types'

  constructor(public event_properties: EventWithArrayTypesProperties) {
    this.event_properties = event_properties
  }
}

export class EventWithConstTypes implements BaseEvent {
  event_type = 'Event With Const Types'
  event_properties = {
    'Boolean Const': true,
    'Integer Const': 10,
    'Number Const': 2.2,
    'String Const': 'String-Constant',
    'String Const WIth Quotes': '"String "Const With" Quotes"',
    'String Int Const': 0,
  }
}

export class EventWithEnumTypes implements BaseEvent {
  event_type = 'Event With Enum Types'

  constructor(public event_properties: EventWithEnumTypesProperties) {
    this.event_properties = event_properties
  }
}

export class EventWithOptionalArrayTypes implements BaseEvent {
  event_type = 'Event With Optional Array Types'

  constructor(public event_properties?: EventWithOptionalArrayTypesProperties) {
    this.event_properties = event_properties
  }
}

export class EventWithOptionalProperties implements BaseEvent {
  event_type = 'Event With Optional Properties'

  constructor(public event_properties?: EventWithOptionalPropertiesProperties) {
    this.event_properties = event_properties
  }
}

export class EventWithTemplateProperties implements BaseEvent {
  event_type = 'Event With Template Properties'

  constructor(public event_properties: EventWithTemplatePropertiesProperties) {
    this.event_properties = event_properties
  }
}

export class EventWithDifferentCasingTypes implements BaseEvent {
  event_type = 'event withDifferent_CasingTypes'

  constructor(
    public event_properties: EventWithDifferentCasingTypesProperties
  ) {
    this.event_properties = event_properties
  }
}

export class EventMaxIntForTest implements BaseEvent {
  event_type = 'EventMaxIntForTest'

  constructor(public event_properties: EventMaxIntForTestProperties) {
    this.event_properties = event_properties
  }
}

export type PromiseResult<T> = { promise: Promise<T | void> }

const getVoidPromiseResult = () => ({ promise: Promise.resolve() })

// prettier-ignore
export class Ampli {
  private disabled: boolean = false;
  private amplitude?: BrowserClient;

  get client(): BrowserClient {
    this.isInitializedAndEnabled();
    return this.amplitude!;
  }

  get isLoaded(): boolean {
    return this.amplitude != null;
  }

  private isInitializedAndEnabled(): boolean {
    if (!this.amplitude) {
      console.error(
        'ERROR: Ampli is not yet initialized. Have you called ampli.load() on app start?',
      );
      return false;
    }
    return !this.disabled;
  }

  /**
   * Initialize the Ampli SDK. Call once when your application starts.
   *
   * @param options Configuration options to initialize the Ampli SDK with.
   */
  load(options: LoadOptions): PromiseResult<void> {
    this.disabled = options.disabled ?? false;

    if (this.amplitude) {
      console.warn(
        'WARNING: Ampli is already intialized. Ampli.load() should be called once at application startup.',
      );
      return getVoidPromiseResult();
    }

    let apiKey: string | null = null;
    if (options.client && 'apiKey' in options.client) {
      apiKey = options.client.apiKey;
    } else if ('environment' in options) {
      apiKey = ApiKey[options.environment];
    }

    if (options.client && 'instance' in options.client) {
      this.amplitude = options.client.instance;
    } else if (apiKey) {
      this.amplitude = amplitude.createInstance();
      const configuration =
        options.client && 'configuration' in options.client
          ? options.client.configuration
          : {};
      return this.amplitude.init(apiKey, ensureGetUserId(), {
        ...DefaultConfiguration,
        ...configuration,
      });
    } else {
      console.error(
        "ERROR: ampli.load() requires 'environment', 'client.apiKey', or 'client.instance'",
      );
    }

    return getVoidPromiseResult();
  }

  /**
   * Identify a user and set user properties.
   *
   * @param userId The user's id.
   * @param properties The user properties.
   * @param options Optional event options.
   */
  identify(
    userId: string | undefined,
    properties: IdentifyProperties,
    options?: EventOptions,
  ): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    if (userId) {
      options = { ...options, user_id: userId };
    }

    const amplitudeIdentify = new amplitude.Identify();
    const eventProperties = properties;
    if (eventProperties != null) {
      for (const [key, value] of Object.entries(eventProperties)) {
        amplitudeIdentify.set(key, value);
      }
    }
    return this.amplitude!.identify(amplitudeIdentify, options);
  }

  /**
   * Track event
   *
   * @param event The event to track.
   * @param options Optional event options.
   */
  track(event: Event, options?: EventOptions, variantId?: string | null , experimentId?: string): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    const defaultEventData = {
      event_properties: {
        category: window.location.host + window.location.pathname,
        ga_session_id: getGASessionId(),
        user_device: getDeviceType(),
        user_agent: window?.navigator?.userAgent,
        source: sessionStorage?.getItem('amplitude-source') || 'entrance',
        ga_client_id: getGAClientId(),
      },
      user_id: ensureGetUserId(),
      user_agent: window?.navigator?.userAgent,
    };

    return this.amplitude!.track(
      {
        ...defaultEventData,
        ...event,
        event_properties: {
          ...defaultEventData.event_properties,
          ...event.event_properties,
          ...(variantId ? { variant_id: variantId } : {}),
          ...(experimentId ? { experiment_id: experimentId } : {}),
        },
      },
      undefined,
      options,
    );
  }

  trackPageViewed(variantId?: string | null , experimentId?: string, pageName?: string, content?: string) {
    const insertID = getInsertId();

    const defaultEventData = {
      event_properties: {
        category: window.location.host + window.location.pathname,
        ga_session_id: getGASessionId(),
        user_device: getDeviceType(),
        user_agent: window?.navigator?.userAgent,
        source: sessionStorage?.getItem('amplitude-source') || 'entrance',
        ga_client_id: getGAClientId(),
      },
      user_id: ensureGetUserId(),
      user_agent: window?.navigator?.userAgent,
    };

    setTimeout(() => {
      this.track({
        ...defaultEventData,
        event_type: pageName ?? 'page viewed checkout ton',
        event_properties: {
          name: pageName ?? 'page viewed checkout ton',
          description: 'Evento disparado na visualização da tela',
          ...(variantId ? { variant_id: variantId } : {}),
          ...(experimentId ? { experiment_id: experimentId } : {}),
          insert_id: insertID,
          ...(content ? { content: content } : {}),
        },
        insert_id: insertID,
      });
    }, 1000);
  }

  /**
   * Event No Properties
   *
   * [View in Tracking Plan](https://data.amplitude.com/test-codegen/Test%20Codegen/events/main/latest/Event%20No%20Properties)
   *
   * Event w no properties description
   *
   * Owner: Test codegen
   *
   * @param options Amplitude event options.
   */
  eventNoProperties(options?: EventOptions) {
    return this.track(new EventNoProperties(), options);
  }

  /**
   * Event Object Types
   *
   * [View in Tracking Plan](https://data.amplitude.com/test-codegen/Test%20Codegen/events/main/latest/Event%20Object%20Types)
   *
   * Event with Object and Object Array
   *
   * Owner: Test codegen
   *
   * @param properties The event's properties (e.g. requiredObject)
   * @param options Amplitude event options.
   */
  eventObjectTypes(
    properties: EventObjectTypesProperties,
    options?: EventOptions,
  ) {
    return this.track(new EventObjectTypes(properties), options);
  }

  /**
   * Event With All Properties
   *
   * [View in Tracking Plan](https://data.amplitude.com/test-codegen/Test%20Codegen/events/main/latest/Event%20With%20All%20Properties)
   *
   * Event w all properties description
   *
   * Owner: Test codegen
   *
   * @param properties The event's properties (e.g. optionalString)
   * @param options Amplitude event options.
   */
  eventWithAllProperties(
    properties: EventWithAllPropertiesProperties,
    options?: EventOptions,
  ) {
    return this.track(new EventWithAllProperties(properties), options);
  }

  /**
   * Event With Array Types
   *
   * [View in Tracking Plan](https://data.amplitude.com/test-codegen/Test%20Codegen/events/main/latest/Event%20With%20Array%20Types)
   *
   * Description for event with Array Types
   *
   * Owner: Test codegen
   *
   * @param properties The event's properties (e.g. requiredBooleanArray)
   * @param options Amplitude event options.
   */
  eventWithArrayTypes(
    properties: EventWithArrayTypesProperties,
    options?: EventOptions,
  ) {
    return this.track(new EventWithArrayTypes(properties), options);
  }

  /**
   * Event With Const Types
   *
   * [View in Tracking Plan](https://data.amplitude.com/test-codegen/Test%20Codegen/events/main/latest/Event%20With%20Const%20Types)
   *
   * Description for event with const types
   *
   * Owner: Test codegen
   *
   * @param options Amplitude event options.
   */
  eventWithConstTypes(options?: EventOptions) {
    return this.track(new EventWithConstTypes(), options);
  }

  /**
   * Event With Enum Types
   *
   * [View in Tracking Plan](https://data.amplitude.com/test-codegen/Test%20Codegen/events/main/latest/Event%20With%20Enum%20Types)
   *
   * Description for event with enum types
   *
   * Owner: Test codegen
   *
   * @param properties The event's properties (e.g. optional enum)
   * @param options Amplitude event options.
   */
  eventWithEnumTypes(
    properties: EventWithEnumTypesProperties,
    options?: EventOptions,
  ) {
    return this.track(new EventWithEnumTypes(properties), options);
  }

  /**
   * Event With Optional Array Types
   *
   * [View in Tracking Plan](https://data.amplitude.com/test-codegen/Test%20Codegen/events/main/latest/Event%20With%20Optional%20Array%20Types)
   *
   * Description for event with optional array types
   *
   * Owner: Test codegen
   *
   * @param properties The event's properties (e.g. optionalBooleanArray)
   * @param options Amplitude event options.
   */
  eventWithOptionalArrayTypes(
    properties?: EventWithOptionalArrayTypesProperties,
    options?: EventOptions,
  ) {
    return this.track(new EventWithOptionalArrayTypes(properties), options);
  }

  /**
   * Event With Optional Properties
   *
   * [View in Tracking Plan](https://data.amplitude.com/test-codegen/Test%20Codegen/events/main/latest/Event%20With%20Optional%20Properties)
   *
   * Event w optional properties description
   *
   * Owner: Test codegen
   *
   * @param properties The event's properties (e.g. optionalArrayNumber)
   * @param options Amplitude event options.
   */
  eventWithOptionalProperties(
    properties?: EventWithOptionalPropertiesProperties,
    options?: EventOptions,
  ) {
    return this.track(new EventWithOptionalProperties(properties), options);
  }

  /**
   * Event With Template Properties
   *
   * [View in Tracking Plan](https://data.amplitude.com/test-codegen/Test%20Codegen/events/main/latest/Event%20With%20Template%20Properties)
   *
   * Event with template properties description
   *
   * Owner: Test codegen
   *
   * @param properties The event's properties (e.g. optional_event_property)
   * @param options Amplitude event options.
   */
  eventWithTemplateProperties(
    properties: EventWithTemplatePropertiesProperties,
    options?: EventOptions,
  ) {
    return this.track(new EventWithTemplateProperties(properties), options);
  }

  /**
   * event withDifferent_CasingTypes
   *
   * [View in Tracking Plan](https://data.amplitude.com/test-codegen/Test%20Codegen/events/main/latest/event%20withDifferent_CasingTypes)
   *
   * Description for case with space
   *
   * Owner: Test codegen
   *
   * @param properties The event's properties (e.g. enum with space)
   * @param options Amplitude event options.
   */
  eventWithDifferentCasingTypes(
    properties: EventWithDifferentCasingTypesProperties,
    options?: EventOptions,
  ) {
    return this.track(new EventWithDifferentCasingTypes(properties), options);
  }

  /**
   * EventMaxIntForTest
   *
   * [View in Tracking Plan](https://data.amplitude.com/test-codegen/Test%20Codegen/events/main/latest/EventMaxIntForTest)
   *
   * Event to test schema validation
   *
   * Owner: Test codegen
   *
   * @param properties The event's properties (e.g. intMax10)
   * @param options Amplitude event options.
   */
  eventMaxIntForTest(
    properties: EventMaxIntForTestProperties,
    options?: EventOptions,
  ) {
    return this.track(new EventMaxIntForTest(properties), options);
  }
}

export const ampli = new Ampli()

// BASE TYPES
type BrowserOptions = amplitude.Types.BrowserOptions

export type BrowserClient = amplitude.Types.BrowserClient
export type BaseEvent = amplitude.Types.BaseEvent
export type IdentifyEvent = amplitude.Types.IdentifyEvent
export type GroupEvent = amplitude.Types.GroupIdentifyEvent
export type Event = amplitude.Types.Event
export type EventOptions = amplitude.Types.EventOptions
export type Result = amplitude.Types.Result

export function ensureGetUserId(): string {
  let userId = localStorage.getItem('amplitude-user-id')
  if (userId !== null) return userId

  userId = window.crypto
    .getRandomValues(new Uint32Array(5))
    .reduce((all, current) => all.concat(current.toString(16)), '')

  localStorage.setItem('amplitude-user-id', userId)

  return userId
}

export function getGASessionId() {
  if (!window) return undefined

  var cookie: any = {}
  document.cookie?.split(';').forEach(function (el) {
    var splitCookie = `${el}`.split('=')
    var key = splitCookie[0].trim()
    var value = splitCookie[1]
    cookie[key] = value
  })
  return cookie['_ga'].substring(6).split('.')[0]
}

export function getDeviceType() {
  if (!window) return 'Server'

  const width = window.innerWidth
  if (width < 768) {
    return 'Mobile'
  } else if (width >= 768 && width < 1024) {
    return 'Tablet'
  }

  return 'Desktop'
}

function getUnixTimestampsFromStartOfMinute() {
  const now = new Date()
  now.setSeconds(0)
  now.setMilliseconds(0)
  return now.getTime()
}

export function getInsertId() {
  const unixTimestamp = getUnixTimestampsFromStartOfMinute()
  const userId = ensureGetUserId()
  return `${userId}-${unixTimestamp}`
}

export function getGAClientId() {
  var cookie = {} as any
  document.cookie?.split(';').forEach(function (el) {
    var splitCookie = `${el}`.split('=')
    var key = splitCookie[0].trim()
    var value = splitCookie[1]
    cookie[key] = value
  })
  return cookie['_ga'].substring(6)
}