import actionCreator from './actionCreator'

const CREATE_BROWSER_ID = 'CREATE_BROWSER_ID'
const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS'
const CREATE_CREDIT_CARD_TRX = 'CREATE_CREDIT_CARD_TRX'

const types = {
  CREATE_BROWSER_ID,
  CREATE_USER_SUCCESS,
  CREATE_CREDIT_CARD_TRX,
}

const createBrowserId = actionCreator(CREATE_BROWSER_ID)
const createUserSuccess = actionCreator(CREATE_USER_SUCCESS)
const createCreditCartTrx = actionCreator(CREATE_CREDIT_CARD_TRX)

export {
  types,
  createBrowserId,
  createUserSuccess,
  createCreditCartTrx,
}
