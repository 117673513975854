import 'src/styles/main.scss'

import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import { Provider } from 'react-redux'
import ReactGA from 'react-ga'
import { PersistGate } from 'redux-persist/integration/react'
import TagManager from 'react-gtm-module'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { MuiThemeProvider } from '@material-ui/core'
import { hotjar } from 'react-hotjar'
import { Experiment } from '@amplitude/experiment-js-client'
import { DatadogSetup } from './config/datadog-setup'
import { ExperimentProvider } from './contexts/amplitude'
import IS_SERVER from './constants/isServer'
import {
  ampli as amplitudeAnalytics,
  DefaultConfiguration,
  ensureGetUserId,
} from './utils/amplitude'

import App from './pages'
import * as serviceWorker from './serviceWorker'
import store, { persistor } from './redux'
import { muiTheme } from './helpers/theme'
import { GA_SETTINGS } from './constants/analytics'
import { LeadProvider } from './contexts/lead'

ReactGA.initialize(GA_SETTINGS.UACode)

const tagManagerArgs = {
  gtmId: 'GTM-NWN5QZ6',
  dataLayerName: 'PageDataLayer',
}

const { REACT_APP_ENVIRONMENT_NAME, REACT_APP_RECAPTCHA_KEY_V3 } = process.env

const hotjarSettings = {
  hjid: 1687427,
  hjsv: 6,
}

if (process.env.REACT_APP_AMPLITUDE_ANALYTICS_API_KEY) {
  amplitudeAnalytics.load({
    client: {
      apiKey: process.env.REACT_APP_AMPLITUDE_ANALYTICS_API_KEY,
      configuration: {
        ...DefaultConfiguration,
        logLevel: 3,
        userId: ensureGetUserId(),
      },
    },
  })
}

if (REACT_APP_ENVIRONMENT_NAME !== 'development') {
  window.dataLayer = []
  TagManager.initialize(tagManagerArgs)
}

hotjar.initialize(hotjarSettings.hjid, hotjarSettings.hjsv)

let experiment

if (
  !IS_SERVER &&
  !experiment &&
  process.env.REACT_APP_AMPLITUDE_ANALYTICS_API_KEY
) {
  experiment = Experiment.initializeWithAmplitudeAnalytics(
    process.env.REACT_APP_AMPLITUDE_EXPERIMENTS_API_KEY,
    {}
  )
}

ReactDOM.render(
  <MuiThemeProvider theme={muiTheme}>
    <DatadogSetup />
    <LeadProvider>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ExperimentProvider
            value={{
              experiments: experiment,
              analytics: amplitudeAnalytics,
            }}
          >
            <Router basename="/checkout">
              <GoogleReCaptchaProvider
                reCaptchaKey={REACT_APP_RECAPTCHA_KEY_V3}
                language="pt-BR"
              >
                <App />
              </GoogleReCaptchaProvider>
            </Router>
          </ExperimentProvider>
        </PersistGate>
      </Provider>
    </LeadProvider>
  </MuiThemeProvider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
