import userActions from '../actions/user'

const defaultState = {
  data: {},
  loading: false,
}

const userReducer = (state = defaultState, action) => {
  switch (action.type) {
    case userActions.SET_USER_DOCUMENT_TYPE: {
      return {
        ...state,
        data: {
          ...state.data,
          user_document_type: action.payload,
        },
      }
    }
    case userActions.SET_BUSINESS_ADDRESS: {
      return {
        ...state,
        data: {
          ...state.data,
          businessAddressFlag: action.payload,
        },
      }
    }
    case userActions.SET_DELIVERY_ADDRESS: {
      return {
        ...state,
        data: {
          ...state.data,
          deliveryAddressFlag: action.payload,
        },
      }
    }
    case userActions.UPDATE_USER: {
      const userData = action.payload

      return {
        ...state,
        data: {
          ...state.data,
          ...userData,
        },
      }
    }
    default:
      return state
  }
}

export default userReducer
