/* eslint-disable prefer-promise-reject-errors */
import queryString from 'query-string'

const defaultHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
}

const notAllowedMethodWithBody = ['GET']
const isBodyNotAllowed = method => notAllowedMethodWithBody.some(
  item => item === method
)

const createRequest = (baseUrl, options = {}) => {
  const requestCreator = method => async (
    path,
    body = {},
    { headers = {}, params = {} } = {}
  ) => {
    const finalHeaders = {
      ...defaultHeaders,
      ...(options.headers || {}),
      ...headers,
    }

    const finalParams = { ...params }
    const queryStr = queryString.stringify(finalParams)
    const queryParameters = queryStr && queryStr.length > 0 ? `?${queryStr}` : ''

    const finalBody = isBodyNotAllowed(method) ? null : JSON.stringify(body)

    const url = `${baseUrl}${path}${queryParameters}`

    return fetch(url, {
      method,
      headers: finalHeaders,
      body: finalBody,
    }).then(async (response) => {
      let data
      try {
        data = await response.json()
      } catch (error) {
        data = null
      }

      if (response.ok) {
        return Promise.resolve({
          data,
          headers: response.headers,
          statusCode: response.status,
        })
      }

      return Promise.reject({
        data,
        headers: response.headers,
        statusCode: response.status,
      })
    })
  }

  return {
    get: (
      path,
      getOptions = {
        headers: {},
        params: {},
      }
    ) => requestCreator('GET')(path, null, getOptions),
    put: requestCreator('PUT'),
    delete: requestCreator('DELETE'),
    post: requestCreator('POST'),
    patch: requestCreator('PATCH'),
  }
}

export default createRequest
