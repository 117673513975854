import {
  filter,
  mergeMap,
} from 'rxjs/operators'
import productService from 'src/services/products'
import productActions from '../actions/products'

const getProducts$ = action$ => action$.pipe(
  filter(action => action.type === productActions.types.FETCH_PRODUCTS),
  mergeMap(
    action => {
      const {catalog, extraProduct} = action.payload
      
     return productService
      .getProducts(catalog, extraProduct)
      .then(productActions.fetchProductsSuccess)
      .catch(productActions.fetchProductsFailed)
    }
  )
)

export default getProducts$
