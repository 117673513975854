import actionCreator from './actionCreator'

const CREATE_CART = 'CREATE_CART'
const CREATE_CART_SUCCESS = 'CREATE_CART_SUCCESS'
const CREATE_CART_FAILED = 'CREATE_CART_FAILED'

const FETCH_REFERRER_METADATA = 'FETCH_REFERRER_METADATA'
const FETCH_REFERRER_METADATA_ERROR = 'FETCH_REFERRER_METADATA_ERROR'

const GET_CART_BY_ID = 'GET_CART_BY_ID'
const GET_CART_BY_ID_SUCCESS = 'GET_CART_BY_ID_SUCCESS'
const GET_CART_BY_ID_FAILED = 'GET_CART_BY_ID_FAILED'

const CHANGE_PRODUCT_QUANTITY = 'CHANGE_PRODUCT_QUANTITY'
const INCREMENT_PRODUCT_QUANTITY = 'INCREMENT_PRODUCT_QUANTITY'
const DECREMENT_PRODUCT_QUANTITY = 'DECREMENT_PRODUCT_QUANTITY'
const REMOVE_PRODUCT = 'REMOVE_PRODUCT'
const UPDATE_CART = 'UPDATE_CART'
const UPDATE_CART_SUCCESS = 'UPDATE_CART_SUCCESS'
const UPDATE_CART_FAILED = 'UPDATE_CART_FAILED'
const CHANGE_PRODUCT_CARRIER = 'CHANGE_PRODUCT_CARRIER'
const ADD_NEW_PRODUCT = 'ADD_NEW_PRODUCT'
const ADD_COUPON = 'ADD_COUPON'
const ADD_COUPON_FAILED = 'ADD_COUPON_FAILED'
const REMOVE_COUPON = 'REMOVE_COUPON'

const SET_ORIGIN = 'SET_ORIGIN'
const SET_ANTICIPATION = 'SET_ANTICIPATION'

const createCart = actionCreator(CREATE_CART)
const createCartSuccess = actionCreator(CREATE_CART_SUCCESS)
const createCartFailed = actionCreator(CREATE_CART_FAILED)

const fetchReferrerMetadata = actionCreator(FETCH_REFERRER_METADATA)
const fetchReferrerMetadataError = actionCreator(FETCH_REFERRER_METADATA_ERROR)

const getCartById = actionCreator(GET_CART_BY_ID)
const getCartByIdSuccess = actionCreator(GET_CART_BY_ID_SUCCESS)
const getCartByIdFailed = actionCreator(GET_CART_BY_ID_FAILED)

const updateCart = actionCreator(UPDATE_CART)
const updateCartSuccess = actionCreator(UPDATE_CART_SUCCESS)
const updateCartFailed = actionCreator(UPDATE_CART_FAILED)

const changeProductQuantity = actionCreator(CHANGE_PRODUCT_QUANTITY)
const incrementProductQuantity = actionCreator(INCREMENT_PRODUCT_QUANTITY)
const decrementProductQuatity = actionCreator(DECREMENT_PRODUCT_QUANTITY)
const removeProduct = actionCreator(REMOVE_PRODUCT)
const changeProductCarrier = actionCreator(CHANGE_PRODUCT_CARRIER)
const addNewProduct = actionCreator(ADD_NEW_PRODUCT)
const addCoupon = actionCreator(ADD_COUPON)
const addCouponFailed = actionCreator(ADD_COUPON_FAILED)
const removeCoupon = actionCreator(REMOVE_COUPON)

const setOrigin = actionCreator(SET_ORIGIN)
const setAnticipation = actionCreator(SET_ANTICIPATION)


export default {
  CREATE_CART,
  CREATE_CART_SUCCESS,
  CREATE_CART_FAILED,
  FETCH_REFERRER_METADATA,
  FETCH_REFERRER_METADATA_ERROR,
  GET_CART_BY_ID,
  GET_CART_BY_ID_SUCCESS,
  GET_CART_BY_ID_FAILED,
  UPDATE_CART,
  UPDATE_CART_SUCCESS,
  UPDATE_CART_FAILED,
  CHANGE_PRODUCT_QUANTITY,
  INCREMENT_PRODUCT_QUANTITY,
  DECREMENT_PRODUCT_QUANTITY,
  REMOVE_PRODUCT,
  CHANGE_PRODUCT_CARRIER,
  ADD_NEW_PRODUCT,
  ADD_COUPON,
  ADD_COUPON_FAILED,
  REMOVE_COUPON,
  SET_ORIGIN,
  SET_ANTICIPATION,
  createCart,
  createCartSuccess,
  createCartFailed,
  fetchReferrerMetadata,
  fetchReferrerMetadataError,
  getCartById,
  getCartByIdSuccess,
  getCartByIdFailed,
  changeProductQuantity,
  incrementProductQuantity,
  decrementProductQuatity,
  removeProduct,
  updateCart,
  updateCartSuccess,
  updateCartFailed,
  changeProductCarrier,
  addNewProduct,
  addCoupon,
  addCouponFailed,
  removeCoupon,
  setOrigin,
  setAnticipation
}
