import actionCreator from './actionCreator'

const SET_CHECKOUT_DATA = 'SET_CHECKOUT_DATA'

const setCheckoutData = actionCreator(SET_CHECKOUT_DATA)

export default {
    SET_CHECKOUT_DATA,
    setCheckoutData,
}
