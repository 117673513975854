import Decimal from 'decimal.js'
import appActions from '../actions/app'
import productActions from '../actions/products'

const defaultState = {
  products: [],
  loading: true,
}

const productReducer = (state = defaultState, action) => {
  const { payload } = action
  switch (action.type) {
    case appActions.CLEAN_APP_DATA:
      return {
        ...defaultState,
        loading: false,
      }
    case productActions.types.FETCH_PRODUCTS: {
      return defaultState
    }
    case productActions.types.FETCH_PRODUCTS_SUCCESS: {
      return {
        products: payload.products.map((product) => {
          const apiProductAmount = new Decimal(product.amount)
          const amount = apiProductAmount
          const installments = 12
          const integer = apiProductAmount.divToInt(installments).toNumber()
          const cents = apiProductAmount
            .mod(installments)
            .div(installments)
            .times(100)
            .round()
            .toNumber()

          return {
            ...product,
            pricing: {
              cents,
              total: amount.toNumber(),
              totalWithoutOffers: amount.toNumber(),
              integer,
              installments,
            },
          }
        }),
        loading: false,
      }
    }
    case productActions.types.FETCH_PRODUCTS_FAILED: {
      return {
        ...state,
        loading: false,
      }
    }
    default:
      return state
  }
}

export default productReducer
