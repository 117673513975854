import { datadogRum } from "@datadog/browser-rum"
import Pkg from "../../package.json"

function initDatadog() {
    const allowedTracingUrls = ["https://www.ton.com.br", "https://sdx.ton.com.br"]
    datadogRum.init({
        applicationId: process.env.REACT_APP_DATADOG_APPLICATION_ID,
        clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
        site: 'datadoghq.com',
        service: Pkg.name,
        env: process.env.REACT_APP_ENVIRONMENT_NAME,
        version: Pkg.version,
        sessionSampleRate: 20,
        sessionReplaySampleRate: 0,
        trackResources: true,
        trackLongTasks: true,
        trackUserInteractions: true,
        enablePrivacyForActionName: true,
        defaultPrivacyLevel: 'mask-user-input',
        startSessionReplayRecordingManually: true,
        traceSampleRate: 20,
        allowedTracingUrls: [(url) => allowedTracingUrls.some((current) => url.startsWith(current))],
    })

    if (process.env.REACT_APP_ENVIRONMENT_NAME === 'production') {
        datadogRum.startSessionReplayRecording()
    }
}

if(process.env.REACT_APP_DATADOG_ENABLED === 'true') {
    initDatadog()
}

export function DatadogSetup() {
    return null
}
