import * as R from 'ramda'
import request from './createRequest/api-ton'

const { REACT_APP_API_ORDERS_HOST } = process.env

const endpoint = '/carts'

const createCart = (initialCartData) =>
  request(REACT_APP_API_ORDERS_HOST)
    .post(endpoint, initialCartData)
    .then((response) => response.data)

const getCartById = (cartId) =>
  request(REACT_APP_API_ORDERS_HOST)
    .get(`${endpoint}/${cartId}`)
    .then((response) => response.data)

const formatProduct = R.applySpec({
  id: R.prop('id'),
  quantity: R.prop('quantity'),
  modifiers: R.prop('modifiers'),
})

const updateOrCreateCart = async (cartData) => {
  const body = {
    coupon: cartData.coupon,
    products: cartData.products.map(formatProduct),
    referrer_id: cartData.referrer_id,
    subcoupon: cartData.subcoupon,
  }

  const { data } = cartData.id
    ? await request(REACT_APP_API_ORDERS_HOST).put(
        `${endpoint}/${cartData.id}`,
        body
      )
    : await request(REACT_APP_API_ORDERS_HOST).post(endpoint, body)

  return data
}

export default {
  createCart,
  getCartById,
  updateOrCreateCart,
}
