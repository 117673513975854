import request from './createRequest/api-ton'

const {
  REACT_APP_API_ORDERS_HOST
} = process.env

const endpoint = '/products'

const getProducts = (catalog = 'tonsuper', extraProduct = false) => {
  const params = {catalog}

  if(extraProduct) {
    params.extra_product = extraProduct
  }

  return request(REACT_APP_API_ORDERS_HOST)
    .get(endpoint, { params })
    .then(response => response.data)

}

const productService = {
  getProducts
}

export default productService